import Icon from '../icon'
import Link from '../link'

function DownloadIcon() {
  return (
    <Link href="/download" className="download-icon">
      <span>
        <Icon className="text-icon_color_01" name={`nav_download_v2`} hover fontSize={16} />
      </span>
    </Link>
  )
}

export default DownloadIcon
